import React from 'react';
import logo from '../../logo.webp';
import { Link, useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <nav className='navbar navbar-expand-lg bg-body-tertiary p-3'>
      <div className='container-fluid'>
        <img src={logo} alt='ICTL Holland' />
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          style={{ border: 'none', outline: 'none' }}
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0 mt-3 mt-md-0 ms-md-3'>
            <li className='nav-item'>
              <Link className='nav-link' to='/containers'>
                Containers
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/items'>
                Items
              </Link>
            </li>
            <li className='nav-item'>
              <button
                type='button'
                className='btn btn-light'
                onClick={handleLogout}
              >
                <i className='fa-solid fa-arrow-right-from-bracket'></i> Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
