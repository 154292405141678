import { useParams, useNavigate } from 'react-router-dom';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import { toast, Toaster } from 'sonner';
import { apiBaseUrl } from '../../api';

function ContainersDetailsPage() {
  const [container, setContainer] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [photos, setPhotos] = useState<Blob[]>([]); // Array to store photos as Blob objects
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const fetchContainer = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/containers/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      console.log(response);

      setContainer(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchContainer();
  }, [id]);

  const handleStartCamera = async () => {
    if (videoRef.current) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: { exact: 'environment' },
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          },
        });
        videoRef.current.srcObject = stream;
      } catch (err) {
        console.error('Error accessing the camera', err);
      }
    }
  };

  const handleCapturePhoto = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          if (blob) {
            setPhotos((prevPhotos) => [...prevPhotos, blob]);
          }
        }, 'image/png');
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files).map((file) => file as Blob);
      setPhotos((prevPhotos) => [...prevPhotos, ...fileArray]);
    }

    event.target.value = '';
  };

  const handleUploadPhotos = async () => {
    setUploading(true);
    const formData = new FormData();
    photos.forEach((photo, index) => {
      formData.append(
        `containerImage[${index}]`,
        photo,
        `containerImage${index + 1}.png`
      );
    });

    try {
      const response = await axios.post(
        `${apiBaseUrl}/containers/upload/${container.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 201) {
        toast.success('Photos uploaded successfuly!');
        fetchContainer();
        setUploading(false);
      }
      setPhotos([]);
    } catch (error) {
      toast.error('Error...');
    }
  };

  const handleCancelPhotos = () => {
    setPhotos([]);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleShow = () => {
      handleStartCamera();
    };

    if (modalElement) {
      modalElement.addEventListener('shown.bs.modal', handleShow);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('shown.bs.modal', handleShow);
      }
    };
  }, [modalRef]);

  let display: ReactNode;

  if (loading) {
    display = (
      <div className='d-flex justify-content-center mt-5'>
        <div
          className='spinner-border text-primary'
          style={{ width: '5rem', height: '5rem' }}
          role='status'
        >
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  } else {
    display = (
      <>
        <div>
          <button onClick={() => navigate(-1)} className='btn btn-light btn-sm'>
            <i className='fa-solid fa-arrow-left'></i> Go Back
          </button>
        </div>
        <div>
          <h5 className='mt-4'>Container: {container.container}</h5>
        </div>
        <div className='d-flex justify-content-center my-5'>
          <button
            type='button'
            className='btn btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#exampleModal'
          >
            <i className='fa-solid fa-camera'></i> Take photos
          </button>
          <button className='btn btn-primary ms-3'>
            <label
              htmlFor='fileInput'
              className='m-0'
              style={{ cursor: 'pointer' }}
            >
              <i className='fa-solid fa-image'></i> Select photos
            </label>
            <input
              id='fileInput'
              type='file'
              multiple
              accept='image/*'
              className='d-none'
              onChange={handleFileUpload}
            />
          </button>
        </div>
        <div className='mt-4'>
          <h6>Captured / selected photos</h6>
          {photos.length ? (
            <div className='row mb-2'>
              <div className='col'>
                <button
                  className='btn btn-primary'
                  onClick={handleUploadPhotos}
                  disabled={!photos.length}
                >
                  {uploading ? (
                    <div
                      className='spinner-border spinner-border-sm text-light'
                      role='status'
                    >
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    <i className='fa-solid fa-upload'></i>
                  )}{' '}
                  Upload
                </button>
                <button
                  className='btn btn-outline-primary ms-2'
                  onClick={handleCancelPhotos}
                  disabled={!photos.length}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : null}
          <div className='row'>
            {photos.length ? (
              photos.map((photo, index) => (
                <div className='col-6 mb-2' key={index}>
                  <img
                    src={URL.createObjectURL(photo)}
                    className='img-fluid'
                    alt={`Captured ${index + 1}`}
                  />
                </div>
              ))
            ) : (
              <div className='col'>
                <p>No photos captured or selected yet</p>
              </div>
            )}
          </div>
        </div>
        <div className='mt-4'>
          <h6>Current photos</h6>
          <div className='row'>
            {container.uploads.length ? (
              container.uploads
                .sort(
                  (a: any, b: any) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
                )
                .map((upload: any) => (
                  <div className='col-6 mb-2' key={upload.id}>
                    <img src={upload.url} className='img-fluid' alt='Image' />
                  </div>
                ))
            ) : (
              <div className='col'>
                <p>No photos yet</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className='container mt-4'>{display}</div>

      <div
        className='modal fade'
        id='exampleModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
        ref={modalRef}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5' id='exampleModalLabel'>
                Take a Photo
              </h1>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <video ref={videoRef} autoPlay className='w-100' />
              <canvas ref={canvasRef} style={{ display: 'none' }} />
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleCapturePhoto}
              >
                Capture
              </button>
            </div>
          </div>
        </div>
      </div>
      <Toaster richColors />
    </>
  );
}

export default ContainersDetailsPage;
